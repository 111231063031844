@tailwind base;
@tailwind components;
@tailwind utilities;
.navbar { 
       @apply text-white hover:text-orange-500 transition ease-linear 
    
}


  
.plus_button_option { 
    @apply flex columns-lg cursor-pointer hover:bg-gray-700
}

.aspect-ratio-box {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* for 16:9 aspect ratio */
}

.aspect-ratio-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
